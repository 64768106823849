import * as React from "react";
import { Link } from "gatsby";
import LinkedIn from "../images/linkedin.inline.svg";

const Footer = () => {
  return (
    <footer>
        <Link
            to="https://www.linkedin.com/in/zlatian-iliev/"
            target="_blank"
        >
            <LinkedIn className="linkedIn" />
        </Link>
    </footer>
  )
}

export default Footer;
