import * as React from "react"
import { Link } from "gatsby"
import headshot from '../images/headshot.png';

const imgStyles = {
  maxWidth: '65px',
  margin: 'auto',
  borderRadius: '50%',
  marginBottom: '10px',
};

const headerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: 'Roboto, Helvetica, Arial',
  color: 'rgba(236, 233, 230, 0.66)',
  margin: '20px 0',
};

const linkStyles = {
  display: 'flex',
  flexDirection: 'column',
  color: 'rgba(236, 233, 230, 0.66)',
  textDecoration: 'none',
}

const Header = () => {
  return (
    <header
      style={headerStyle}
    >
      <Link to="/" style={linkStyles}>
        <img
          src={headshot}
          alt="head shot of Zlatian Iliev"
          style={imgStyles}
        />
        <p>ZLATIAN IVAYLOV ILIEV</p>
      </Link>
    </header>
  )
}

export default Header;
